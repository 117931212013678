<template>
  <div class="container">
    <create-slider
      v-model="slider"
      title="Sliderni o'zgartirish"
    ></create-slider>
    <div class="d-flex container">
      <a-button class="bg-warning text-white mr-2 " @click="editData"
        >O'zgartirish</a-button
      >
      <a-button class="bg-danger text-white " @click="deleteSlider"
        >O'chirish</a-button
      >
    </div>

    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos slider title, kategoriya, content va rasmni kiriting
      </p>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CreateSlider from '../../components/Slider/CreateSlider.vue';
export default {
  props: ['sliderId'],
  components: {
    CreateSlider,
  },
  data() {
    return {
      img: true,
      inValid: false,
      slider: {
        title: '',
        content: '',
        category: '',
        image: {},
      },
    };
  },

  methods: {
    ...mapActions({
      getOneSlider: 'slider/getOneSlider',
      deleteOneSlider: 'slider/deleteSlider',
      editSlider: 'slider/editSlider',
    }),
    closeModal(bool) {
      this.inValid = bool;
    },
    editData() {
      const obj = {
        title: this.slider.title,
        content: this.slider.content,
        category: this.slider.category.id
          ? this.slider.category.id
          : this.slider.category,
        image: this.slider.image,
      };
      for (let i in obj) {
        if (obj[i] === '') {
          this.inValid = true;
          return;
        }
      }

      if (!obj.image.obj) {
        delete obj.image;
      } else {
        obj.image = this.slider.image.files;
      }
      this.editSlider({ obj: obj, id: this.sliderId })
        .then(() => {
          this.$notification['success']({
            message: "Slider o'zgardi ",
            description: "Serverda Slider o'zgartirildi ):",
          });
          setTimeout(() => {
            this.$router.push({ name: 'Slider' });
          }, 1000);
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description: 'Serverda Xatolik Boldi ):' + error.message,
          });
          setTimeout(() => {
            this.$router.push({ name: 'Slider' });
          }, 1000);
        });
    },

    deleteSlider() {
      this.deleteOneSlider(this.sliderId)
        .then(() => {
          this.$notification['success']({
            message: "O'chirildi",
            description: "Serverda Slider o'chirildi ):",
          });
          setTimeout(() => {
            this.$router.push({ name: 'Slider' });
          }, 1000);
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description: 'Serverda Xatolik Boldi ):' + error.message,
          });
          setTimeout(() => {
            this.$router.push({ name: 'Slider' });
          }, 1000);
        });
    },
  },

  created() {
    this.getOneSlider(this.sliderId)
      .then((res) => {
        const { title, content, image, category } = res;

        this.slider = {
          title,
          content,
          image,
          category: category,
        };
      })
      .catch((error) => {
        this.$notification['error']({
          message: 'Xatolik',
          description: 'Serverda Xatolik Boldi ):' + error.message,
        });
        setTimeout(() => {
          this.$router.push({ name: 'Slider' });
        }, 1000);
      });
  },
};
</script>
