<template>
  <section class="container mt-4">
    <h4 class="ml-sm-2 ml-md-4 ml-lg-4">{{ title }}</h4>
    <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
      <a-form-item label="Sarlavha">
        <a-input v-model="value.title" :value="value.title" />
      </a-form-item>
      <a-form-item label="Text">
        <a-input
          type="textarea"
          v-model="value.content"
          :value="value.content"
          rows="5"
        />
      </a-form-item>
      <a-form-item label="Rasm">
        <a-input type="file" @change="imUpload" />
        <!-- <div v-if="!img"></div> -->
        <img
          :src="this.value.image"
          alt="Rasm tanlash"
          v-if="!previewImg"
          class="mt-3"
          width="100"
          height="100"
        />
        <img
          :src="cPreviewImg"
          :alt="cPreviewImg"
          v-else
          class="mt-3"
          width="100"
          height="100"
        />
      </a-form-item>
      <a-form-item label="Kategoriya">
        <a-select
          show-search
          :value="secondValue"
          v-model="secondValue"
          placeholder="Kategoriya tanlash"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch"
          @change="handleChange"
        >
          <a-select-option
            v-for="val in catgeoriy"
            :key="val.id"
            :value="val.id"
          >
            {{ val.name_ru }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import debounce from 'lodash/debounce';
export default {
  components: {},
  data() {
    this.handleSearch = debounce(this.handleSearch, 800);
    return {
      catgeoriy: [],
      parentCategory: [],
      error: '',
      fetching: false,
      data: [],
      secondValue: '',
      oldImg: '',
      previewImg: '',
    };
  },
  computed: {
    labelValue() {
      return !this.value.category ? this.data : this.value.category;
    },
    cPreviewImg() {
      return this.oldImg
        ? this.previewImg
          ? URL.createObjectURL(this.previewImg)
          : this.oldImg
        : this.oldImg;
    },
  },
  props: {
    value: {
      type: Object,
      deafult: () => ({}),
    },
    title: String,
    img: Boolean,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  methods: {
    ...mapActions({
      getChildCategory: 'category/getChildCategory',
      getOneCategory: 'category/getOneCategory',
      loadAllCategory: 'category/loadAllCategory',
      filterCategory: 'category/filterCategory',
    }),
    handleChange(categoriyVal) {
      this.secondValue = categoriyVal;
      this.value.category = this.secondValue;
    },
    handleSearch(val) {
      this.filterCategory(val).then((res) => {
        this.catgeoriy = res.results;
      });
    },

    parentChangeHandler(id) {
      this.getOneCategory(id).then((res) => {
        const arr = res.children.map((item) => {
          return {
            id: item.id,
            name: item.name,
            main: item.main,
          };
        });
        this.catgeoriy = arr;
        this.$notification['success']({
          message: 'Success',
          description: 'Child categoriyalar qoshildi',
        });
      });
    },
    closeNotify() {
      this.error = '';
    },
    imUpload(e) {
      const files = e.target.files[0];
      this.oldImg = files;
      this.previewImg = files;
      const obj = {
        files,
        obj: true,
      };
      this.value.image = obj;
      this.$notification['success']({
        message: 'Rasm saqlandi',

        description: "Rasm saqlandi va qo'shildi",
      });
    },
  },

  created() {
    this.loadAllCategory()
      .then(({ results }) => {
        this.parentCategory = results;
      })
      .catch((error) => {
        console.log(error);
      });
    this.getChildCategory()
      .then(({ results }) => {
        this.catgeoriy = results;
      })
      .catch((error) => {
        this.error = error;
        this.$notification['error']({
          message: 'Xatolik! Server bilan xatolik',
          description: error.message,
        });

        setTimeout(() => {
          this.$router.push({ name: 'Slider' });
        }, 1000);
      });
  },
  updated() {
    if (!this.oldImg) {
      this.oldImg = this.value.image;
    }
    if (this.value.category.name) {
      this.secondValue = this.value.category.name_ru;
    }
  },
};
</script>

<style scoped>
input[type='file'] {
  cursor: pointer;
  width: 100%;
  height: 37px;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
}
input[type='file']:focus {
  outline: none;
}

input[type='file']:before {
  width: 100%;
  font-size: 15px;
  line-height: 27px;

  content: 'Rasm Yuklash';
  display: inline-block;
  background: white;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
}

input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
}
</style>
